<template functional>
	<img :src="$options.methods.starSvgFile(props.rating, props.type)" width="90" height="18" decoding="async" alt="stars" />
</template>

<script>
export default {
	props: {
		rating: {
			type: Number,
			required: true,
		},
		type: {
			type: String,
			default: "",
		},
	},
	methods: {
		starSvgFile(rating, type) {
			const r = rating;

			if (type === "canstar") {
				if (r > 4) return "/icons/starsBlue5.svg"
				if (r > 3) return "/icons/starsBlue4.svg"
				return "/icons/starsBlue3.svg"
			}
			if (r > 4.75) return "/icons/stars5.svg"
			if (r > 4.5) return "/icons/stars475.svg"
			if (r > 4.25) return "/icons/stars45.svg"
			if (r > 4) return "/icons/stars425.svg"
			if (r > 3.75) return "/icons/stars4.svg"
			if (r > 3.5) return "/icons/stars375.svg"
			if (r > 3.25) return "/icons/stars35.svg"
			if (r > 3) return "/icons/stars325.svg"
			return "/icons/stars3.svg"
		},
	},
};
</script>
