<template>
	<div>
		<!-- Review Mobile -->
		<div class="tablet:hidden">
			<dl class="flex flex-col justify-center">
				<dt class="order-2 flex flex-wrap">
					<span class="text-14 w-full">Based on {{ reviewTotal }} reviews from</span>
					<nuxt-link v-for="review in reviews" :key="review.platformUrl" rel="nofollow" to="/about-us/reviews/" class="font-bold text-goBlack cursor-pointer mr-8 whitespace-nowrap">
						{{ review.platform }}
					</nuxt-link>
				</dt>

				<template v-if="!!reviews[0]">
					<dd class="order-1 text-24">
						<span class="font-bold text-goPink">{{ reviews[0].currentRating }}</span>
						<span v-show="!!reviews[0].maxRating" class="text-goGrayDark"> /{{ reviews[0].maxRating }}</span>
					</dd>

					<dd class="order-1 mb-12">
						<stars :rating="reviews[0].stars" width="120" height="24" />
					</dd>
				</template>
			</dl>
		</div>

		<!-- Review Tablet -->
		<div class="hidden tablet:flex tablet:justify-between divide-x divide-black/10">
			<review v-for="(review, index) in reviews" v-bind="review" :key="index" class="p-14 w-full" />
		</div>
	</div>
</template>

<script>
import review from "~/components/review.vue"
import stars from '~/components/stars.vue';

export default {
	components: {
		review,
		stars,
	},
	computed: {
		reviewTotal() {
			return this.reviews.reduce((sum, review) => sum + parseInt(review.reviewCount), 0);
		},
		reviews() {
			return this.$store.getters['reviews/byPlatforms'](["Google", "Facebook", "Trustpilot"]);
		},
	},
};
</script>
