<template functional>
	<dl :class="[data.class, data.staticClass]" class="flex flex-col justify-center items-center">
		<dd class="text-24">
			<span class="font-bold" style="color: var(--var-color, #E21266)">{{ props.currentRating }}</span>
			<span v-if="props.maxRating">/{{ props.maxRating }}</span>
		</dd>

		<dd class="mb-12">
			<component :is="injections.components.stars" :rating="props.stars"></component>
		</dd>

		<dt class="text-16 h-24">
			<span class="text-goBlack">
				<a :href="props.reviewUrl || props.platformUrl" rel="nofollow noopener" target="_blank">
					<span v-if="false"></span>
					<img v-else-if="props.platform === 'Rankers'" src="/images/logo_rankersNZ.png" alt="Rankers logo" loading="lazy" decoding="async" width="88" height="22" class="translate-y-2"/>
					<img v-else-if="props.platform === 'Google'" src="/images/logo_google.png" alt="Google logo" loading="lazy" decoding="async" width="74" height="23"/>
					<img v-else-if="props.platform === 'Facebook'" src="/images/logo_facebook.png" alt="Facebook logo" loading="lazy" decoding="async" width="88" height="18"/>
					<img v-else-if="props.platform === 'Review Centre'" src="/images/logo_reviewCentre.png" alt="Review Centre logo" loading="lazy" decoding="async" width="100" height="25"/>
					<img v-else-if="props.platform === 'Trustpilot'" src="/images/logo_trustPilot.png" alt="Trustpilot logo" loading="lazy" decoding="async" width="92" height="23" class="-translate-y-2"/>
					<span v-else-if="props.platform === 'Apple App Store'" class="font-bold">Apple App Store</span>
					<span v-else-if="props.platform === 'Google Play'" class="font-bold">Google Play Store</span>
				</a>
			</span>
		</dt>

		<dd class="text-goGrayDarker text-14 w-full flex flex-col justify-center items-center">{{ props.reviewCount }} reviews</dd>
	</dl>
</template>

<script>
import stars from '~/components/stars.vue';

export default {
	inject: {
		components: {
			default: {
				stars
			}
		}
	},
};
</script>
